<!--
 * @Author: 张博洋
 * @Date: 2021-08-10 13:53:11
 * @LastEditTime: 2022-02-25 16:07:18
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/order/detail.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wraper">
    <div class="detail">
      <statusCard :state="state"
                  :id="detail.id"
                  :createTime="detail.createTime"
                  :payExpireTime="detail.payExpireTime"
                  :prescriptionOrderId="detail.prescriptionOrderId"
                  @changeState="changeState"
                  @refreshDetail="refreshDetail"></statusCard>

      <div class="record"
           @click="checkIm"
           v-if="state === 3 || state === 4">
        <van-cell title="咨询对话记录"
                  is-link
                  value="查看" />
      </div>

      <div class="order-type mt8">
        <h1>订单类型：{{detail.orderType === 1 ? '图文咨询' : '图文问诊'}}</h1>
        <h2>就诊人信息</h2>
        <van-cell title="姓名"
                  :value="detail.patientName" />
        <van-cell title="性别"
                  :value="detail.patientSex" />
        <van-cell title="年龄"
                  :value="detail.patientAge ? detail.patientAge + '岁' : ''" />
        <van-cell class="noline"
                  title="病情描述" />
        <v-clamp v-if="text.length"
                 autoresize
                 location="end"
                 :max-lines="4"
                 :expanded="expanded"
                 @clampchange="clampchange">
          {{ text }}
          <span class="slotBtn"
                v-if="btnShow"
                slot="after"
                @click="toggle">
            {{ expanded?  '[收起]' :'[更多]' }}
          </span>
        </v-clamp>
        <van-cell title="图片" />
        <div class="images"
             ref="images">
          <div class="img"
               v-for="(img, imgIndex) in formatImage(detail.imageUrls)"
               :key="imgIndex">
            <img :src="img"
                 :width="imgWidth"
                 :height="imgWidth"
                 alt=""
                 @click="previewImage(img)">
          </div>

        </div>
      </div>

      <div class="order-refund mt8 mb8"
           v-if="detail.state === 7">
        <h1>退款明细</h1>
        <van-cell title="退款单ID"
                  :value="detail.refundSerialNo" />
        <van-cell class="totalPrice"
                  title="退款金额"
                  :value="`¥${detail.refundAmount}`" />
        <van-cell title="退款方式"
                  value="微信" />

      </div>

      <div class="order-detail mt8 mb8">
        <h1>订单明细</h1>
        <van-cell title="订单ID"
                  :value="detail.orderNo" />
        <van-cell title="下单时间"
                  :value="$.replyTime(detail.createTime)" />
        <van-cell title="支付方式"
                  value="微信"
                  v-if="state !== 1" />
        <van-cell class="totalPrice"
                  title="订单总金额"
                  :value="`¥${detail.orderAmount}`" />
        <van-cell title="支付流水号"
                  :value="detail.paySerialNo"
                  v-if="state !== 1" />
      </div>
    </div>

    <div class="btn-group mt8"
         v-if="state === 1">
      <!-- <div class="price">实付款 <span>¥</span><span class="num">{{detail.orderAmount}}</span></div> -->
      <van-button round
                  style="flex:1"
                  @click="cancelOrder">取消咨询</van-button>
      <van-button type="primary"
                  round
                  class="submit"
                  @click="pay(detail.id)">去支付</van-button>
    </div>
    <div class="btn-group mt8"
         v-else-if="state === 2">
      <van-button round
                  style="flex:1"
                  @click="backHome">回到首页</van-button>
      <van-button class="doctor"
                  style="width:60%;margin-left: 10px;"
                  @click="checkIm"
                  round>联系医生</van-button>

    </div>

    <div class="btn-group mt8"
         v-if="(state === 4 || state === 7)  && detail.evaluate === 0">
      <van-button class="evaluate"
                  color="#1AB370"
                  @click="toEvaluate"
                  round>去评价</van-button>
    </div>

    <div class="btn-group mt8"
         v-if="(state === 4 || state === 7) && detail.evaluate === 1">
      <van-button type="primary"
                  class="check-evaluate"
                  @click="checkEvaluate"
                  round>查看评价</van-button>
    </div>
    <evaluate-panel :evaluateShow="evaluateShow"
                    :orderId="detail.id"
                    @refresh="refresh"
                    @close="closePanel"
                    :evaluateDetail="evaluateDetail"
                    :evaluateType="evaluateType"></evaluate-panel>
  </div>

</template>

<script>
import { ImagePreview } from 'vant'
import statusCard from './components/statusCard.vue'
import VClamp from 'vue-clamp'
import evaluatePanel from './components/evaluatePanel.vue'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      maxWidth: 327,
      imgWidth: '',
      imageUrls: '',
      text: '',
      detail: {
        patientAge: '',
        orderAmount: '',
        patientSex: '',
      },
      state: '',
      c: false,
      btnShow: false,
      expanded: false, //收否展开
      evaluateShow: false,
      evaluateDetail: {},
      evaluateType: '',
    }
  },
  created() {
    this.getOrderDetail()
  },
  activated() {
    this.getOrderDetail()
  },
  mounted() {
    console.log(this.$refs.images.clientWidth)
    this.maxWidth = this.$refs.images.clientWidth
    this.imgWidth = Math.floor((this.maxWidth - 31) / 4)
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from)
    if (from.name === 'order.list') {
      console.log('hahahah')
      next((vm) => {
        vm.backUrl = from.name
      })
    } else {
      console.log('11111')
      next((vm) => {
        vm.backUrl = from.name
      })
    }
  },
  components: {
    statusCard,
    VClamp,
    evaluatePanel,
  },
  methods: {
    formatImage(imageUrls) {
      if (imageUrls) {
        return imageUrls.split(',')
      }
      return []
    },
    previewImage(img) {
      ImagePreview({
        images: [img],
        closeable: true,
      })
    },
    changeState(payload) {
      this.state = payload
    },
    getOrderDetail() {
      // alert(this.$route.query.orderId)
      this.$axios({
        type: 'post',
        url: 'order/getServiceOrderDetail',
        data: {
          id: this.$route.query.orderId,
        },
        elseData: {
          ajaxLoading: true,
        },
      }).then((res) => {
        this.detail = res.d
        console.log(this.detail)
        this.text = this.detail.diseaseDesc
        this.imageUrls = this.detail.imageUrls
        this.state = res.d.state
        this.detail.patientSex = res.d.patientSex === 1 ? '男' : '女'
      })
    },
    refreshDetail() {
      this.getOrderDetail()
    },
    refresh(payload) {
      this.evaluateShow = false
      this.refreshDetail()
    },
    pay(id) {
      wx.miniProgram.redirectTo({
        url: `/pages/pay/pay?id=${id}`,
      })
      // alert(id)
    },

    checkIm() {
      this.$router.push({
        name: 'im.chat',
        query: {
          state: this.detail.state,
          doctorAccountId: this.detail.doctorAccountId,
          orderid: this.detail.id,
          roomId: this.detail.roomId,
          orderType: 2,
        },
      })
    },
    toggle() {
      this.c = true
      this.expanded = !this.expanded
    },
    clampchange(expanded) {
      if (!expanded && !this.c) {
        this.btnShow = false
      } else {
        this.btnShow = true
      }
    },
    checkEvaluate() {
      this.evaluateShow = true
      this.getEvaluateDetail()
      this.evaluateType = 'check'
    },
    toEvaluate() {
      this.evaluateShow = true
      this.evaluateType = 'add'
    },
    getEvaluateDetail() {
      this.$axios({
        type: 'post',
        url: 'order/queryEvaluate',
        data: {
          id: this.$route.query.orderId,
        },
      }).then((res) => {
        console.log(res)
        this.evaluateDetail = res.d
      })
    },
    closePanel() {
      this.evaluateShow = false
    },
    backHome() {
      wx.miniProgram.reLaunch({ url: '/pages/index/index' })
    },
    cancelOrder() {
      console.log(this.backUrl)
      Dialog.confirm({
        title: '操作确认',
        confirmButtonColor: '#1AB370',
        message: '是否确认取消？',
      })
        .then(() => {
          this.$axios({
            type: 'post',
            url: 'order/cancel',
            data: {
              id: this.$route.query.orderId,
            },
            elseData: {
              loading: true,
            },
          }).then((res) => {
            if (this.backUrl === 'order.list') {
              this.$router.replace({
                name: this.backUrl,
              })
            } else {
              wx.miniProgram.reLaunch({ url: '/pages/index/index' })
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.wraper {
  display: flex;
  flex-direction: column;
}
.detail {
  overflow-y: auto;
  flex: 1;
  .record {
    margin: 0 8px;
    background: #ffffff;
    border-radius: 8px;
    .van-cell {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      .van-cell__value {
        font-size: 14px;
        font-weight: 400;
        color: #1ab370;
      }
    }
  }
  .order-type,
  .order-detail,
  .order-refund {
    margin: 0 8px;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px 16px;
    h1 {
      line-height: 49px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    h2 {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
    }
    /deep/.van-cell {
      padding-left: 0;
      padding-right: 0;
      /deep/.van-cell__title {
        color: #666;
        flex: none;
      }

      .van-cell__value {
        word-wrap: unset;
        color: #333;
      }
    }

    .van-cell::after {
      left: 0;
      right: 0;
      // border: none;
    }
    .noline::after {
      border: none;
    }
    .totalPrice {
      /deep/.van-cell__value {
        font-size: 16px;
        font-weight: 500;
        color: color(primarySub) !important;
      }
    }
    .images {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .img {
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .img:nth-child(4n) {
        margin-right: 0px;
      }
    }
  }
}
.slotBtn {
  color: color(primary);
}

.btn-group {
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  background: #ffffff;
  .price {
    height: 44px;
    width: 71%;
    background: #333333;
    border-radius: 22px 0px 0px 22px;
    line-height: 44px;
    color: #fff;
    padding-left: 32px;
    display: flex;
    align-items: baseline;
    span.num {
      font-size: 20px;
      font-weight: 500;
      margin-left: 0px;
    }
    span {
      font-size: 14px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin-left: 13px;
    }
  }
  .submit {
    border: none;
    width: 60%;
    height: 44px;
    background: color(primarySub);
    margin-left: 10px;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0;
  }
  .doctor,
  .buy,
  .evaluate {
    color: #fff;
    background: color(primary);
    width: 100%;
  }
  .check-evaluate {
    width: 100%;
  }
}
</style>