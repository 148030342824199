<!--
 * @Author: 张博洋
 * @Date: 2021-08-10 14:14:59
 * @LastEditTime: 2021-09-27 16:27:08
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/order/components/statusCard.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div @click="handleClick">
    <div class="card card-unpay"
         v-if="newState === 1">
      <p class="status">
        <van-icon name="underway-o"
                  size="24" />
        <span class="ml12">待支付</span>
      </p>
      <p class="tip mt18">请尽快支付，
        <van-count-down :time="time"
                        format="mm 分 ss 秒"
                        @finish="changeState" />
        后将自动关闭！
      </p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
    </div>

    <div class="card card-new"
         v-if="newState === 2">
      <p class="status">
        <van-icon name="passed"
                  size="24" />
        <span class="ml12">新建</span>
      </p>
      <p class="tip mt18">您已完成支付，请等待医生接诊！</p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
      <van-icon class="icon-ellipsis"
                id="icon-ellipsis"
                size="24"
                name="ellipsis"
                color="#999"
                @click="openPop">
        <div class="pop"
             v-if="showPopover"
             @click="onSelect">
          <div>
            申请退款
          </div>
        </div>

      </van-icon>

    </div>

    <div class="card card-serving"
         v-if="newState === 3">
      <p class="status">
        <van-icon name="passed"
                  size="24" />
        <span class="ml12">服务中...</span>
      </p>
      <p class="tip mt18">医生已接诊，咨询正在进行中……
      </p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
      <div class="checkPrescription"
           v-if="prescriptionOrderId"
           @click="checkPrescription(prescriptionOrderId)">查看处方</div>
    </div>

    <div class="card card-finsh"
         v-if="newState === 4">
      <p class="status">
        <van-icon name="passed"
                  size="24" />
        <span class="ml12">已完成</span>
      </p>
      <p class="tip mt18">病情如有变化，可再次咨询医生</p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
      <div class="checkPrescription"
           v-if="prescriptionOrderId"
           @click="checkPrescription(prescriptionOrderId)">查看处方</div>
    </div>

    <div class="card card-cancle"
         v-if="newState === 5">
      <p class="status">
        <span class="iconfont icon-quxiao"></span>
        <span class="ml12">已取消</span>
      </p>
      <p class="tip mt18">咨询服务已关闭</p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
      <div class="checkPrescription"
           v-if="prescriptionOrderId"
           @click="checkPrescription(prescriptionOrderId)">查看处方</div>
    </div>

    <div class="card card-refunding"
         v-if="newState === 6">
      <p class="status">
        <van-icon name="info-o"
                  size="24" />
        <span class="ml12">退款中</span>
      </p>
      <p class="tip mt18">订单退款中…</p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
    </div>

    <div class="card card-refundSuccess"
         v-if="newState === 7">
      <p class="status">
        <van-icon name="passed"
                  size="24" />
        <span class="ml12">退款成功</span>
      </p>
      <p class="tip mt18">你已取消订单，咨询服务已关闭</p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
      <div class="checkPrescription"
           v-if="prescriptionOrderId"
           @click="checkPrescription(prescriptionOrderId)">查看处方</div>
    </div>

    <div class="card card-refundFail"
         v-if="newState === 8">
      <p class="status">
        <van-icon name="passed"
                  size="24" />
        <span class="ml12">退款失败</span>
      </p>
      <p class="tip mt18">订单退款失败…</p>
      <p class="time mt8">{{$.replyTime(createTime)}}</p>
      <div class="checkPrescription"
           v-if="prescriptionOrderId"
           @click="checkPrescription(prescriptionOrderId)">查看处方</div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'

import '@/assets/iconfont.css'
export default {
  data() {
    return {
      time: '',
      newState: '',
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actionsList: [{ text: '申请退款', className: 'pop' }],
    }
  },
  watch: {
    state: {
      handler(val) {
        this.newState = val
      },
    },
    payExpireTime: {
      handler(val) {
        this.time = val
      },
    },
  },
  created() {},
  props: ['state', 'payExpireTime', 'id', 'createTime', 'prescriptionOrderId'],
  methods: {
    openPop() {
      if (this.showPopover) return
      this.showPopover = true
    },
    handleClick(e) {
      console.log(e)
      if (e.target.id !== 'icon-ellipsis' && this.showPopover) {
        this.showPopover = false
      }
    },
    changeState() {
      console.log('倒计时结束了，通知父组件')
      this.newState = 5
      this.$emit('changeState', this.newState)
    },
    checkPrescription(id) {
      // this.$router.push({
      //   name: 'im.prescription',
      //   query: {
      //     id: id
      //   }
      // })
      wx.miniProgram.navigateTo({
        url: `/pages/protocol/protocol?rpId=${id}`,
      })
    },
    onSelect(action) {
      console.log(action)
      this.$axios({
        type: 'post',
        url: 'pay/detectRefundApply',
        data: {
          id: this.id,
        },
        elseData: {
          ajaxLoading: true,
        },
      }).then((res) => {
        console.log(res)
        if (res.d.canRefund === 1) {
          Dialog.confirm({
            title: '退款确认',
            confirmButtonColor: '#1AB370',
            message: '确认要申请退款吗',
          })
            .then(() => {
              this.$axios({
                type: 'post',
                url: 'pay/refundApply',
                data: {
                  id: this.id,
                },
                elseData: {
                  ajaxLoading: true,
                },
              }).then((res) => {
                console.log(res)
                Dialog.alert({
                  message: '退款成功',
                  confirmButtonColor: '#1AB370',
                }).then(() => {
                  this.$emit('refreshDetail')
                })
              })
            })
            .catch(() => {
              // on cancel
            })
        } else {
          Dialog.alert({
            message: '医生已接诊，无法退款',
            confirmButtonColor: '#1AB370',
          }).then(() => {
            // on close
          })
        }
      })
    },
  },
}
</script>

 <style lang="scss" scoped>
.card-unpay {
  .van-icon {
    color: #ff8200;
  }
  span {
    color: #ff8200;
    font-size: 24px;
    font-weight: 600;
  }
}
.card-new,
.card-serving,
.card-finsh,
.card-refundSuccess {
  .van-icon {
    color: #1ab370;
  }
  span {
    color: #1ab370;
    font-size: 24px;
    font-weight: 600;
  }
}
.card-cancle {
  span {
    color: #999999;
    font-size: 24px;
    font-weight: 600;
  }
}
.card-refunding {
  .van-icon {
    color: #ff8200;
  }
  span {
    color: #ff8200;
    font-size: 24px;
    font-weight: 600;
  }
}

.card-refundFail {
  .van-icon {
    color: #ff4d4f;
  }
  span {
    color: #ff4d4f;
    font-size: 24px;
    font-weight: 600;
  }
}
.card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: auto;
  background: #ffffff;
  border-radius: 8px;
  margin: 8px;
  position: relative;
  padding: 10px;
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tip {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .time {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
  .checkPrescription {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 68px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    line-height: 24px;
  }
  .icon-ellipsis {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
.pop {
  position: absolute;
  top: 35px;
  right: 0px;
  color: #333;

  & > div {
    width: 128px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    box-shadow: 0 2px 12px #e1e1e1;
    border-radius: 4px;
  }
}
.pop:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  color: #fff;
  top: 1px;
  border-top-width: 0;
  border-bottom-color: currentColor;
  box-shadow: 0 2px 12px #e1e1e1;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  right: 10px;
}
</style>